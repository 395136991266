﻿/* global $, paper, SC, Sketch, console */
/* eslint-env browser */
(function () {
   'use strict';

   /*
   Copyright 2017 Government Software Assurance Corporation
   Author: Chet Zema
   
   To rebuild the Lexer/Parser run ANTLR against Sketch.g4 on a machine with Java, use -Dlanguage=JavaScript
   Copy SketchLexer.js, SketchParser.js, and SketchListener.js to this directory.
   Run `npm run webpack` from UI directory.  This concatenates the antlr JS runtime, along with the Lexer/Parser and this listener implementation.  Output is wwwroot\antlr.js
   Include antlr.js in any webpage that needs to parse a sketch string.
   
   This listener must be kept in sync with \RealEstate\Calculation\Sketch\SketchProcessor.cs
   
   To use: SketchProcessor(sketchString) => { Array of Areas, Array of Errors }.  Each Area has an Array of Lines.
   */

   var antlr4 = require('antlr4/index');
   var SketchLexer = require('./SketchLexer');
   var SketchParser = require('./SketchParser');
   var SketchListener = require('./SketchListener');

   window.SketchProcessor = function (input) {
      var errors = [];
      SketchErrorListener.INSTANCE.initialize(errors);

      var chars = new antlr4.InputStream(input);
      var lexer = new SketchLexer.SketchLexer(chars);
      lexer.addErrorListener(SketchErrorListener.INSTANCE);
      var tokens = new antlr4.CommonTokenStream(lexer);
      var parser = new SketchParser.SketchParser(tokens);
      parser.addErrorListener(SketchErrorListener.INSTANCE);
      var tree = parser.sketch();
      var listener = new Listener(errors);
      antlr4.tree.ParseTreeWalker.DEFAULT.walk(listener, tree);

      return { areas: listener.GetAreas(), errors: errors };
   };

   // The actual listener that converts the AST into a usable data structure.
   var Listener = function (errors) {
      SketchListener.SketchListener.call(this);

      this.errors = errors;
      this.areas = [];
      this.area_stack = [];
      this.point_stack = [[0, 0]];

      return this;
   };

   Listener.prototype = Object.create(SketchListener.SketchListener.prototype);
   Listener.prototype.constructor = Listener;
   Listener.prototype.GetAreas = function () {
      return this.areas;
   };

   Listener.prototype.exitSketch = function (ctx) {
      while (this.area_stack.length > 0) {
         this.closeArea(this.area_stack.shift());
      }
   };

   Listener.prototype.enterArea = function (ctx) {
      var name = ctx.name().getText();

      var area = {
         cd: name,
         lines: [],
         data: {},
         area_override: null,
         centroid: [0, 0],
         area_inches: 0,
         perimeter_inches: 0,
      };

      this.areas.push(area);
      this.area_stack.unshift(area);

      if (ctx.NUMBER() !== null) {
         area.area_override = parseInt(ctx.NUMBER().getText(), 10) * 144;
      }

      if (ctx.multiplier() != null) {
         area.data.MULTIPLIER = parseInt(ctx.multiplier().NUMBER().getText(), 10);
      }

      // The point on the top of the stack is the current point for the current area.  It is modified as the area is traversed.
      // Does it start at the last point, or does the area have an origin override?
      var origin = ctx.origin();
      var x = this.point_stack[0][0];
      var y = this.point_stack[0][1];
      if (origin !== null) {
         x = this.toInches(origin.NUMBER()[0].getText());
         y = this.toInches(origin.NUMBER()[1].getText());
      }
      this.point_stack.unshift([x, y]);
   };

   Listener.prototype.exitArea = function (ctx) {
      this.closeArea(this.area_stack.shift());
      this.point_stack.shift();
   };

   Listener.prototype.enterDatum = function (ctx) {
      var name = ctx.name().getText().toUpperCase();
      var val = ctx.data_val().getText();
      var area = this.area_stack[0];

      switch (name.toUpperCase()) {
         case "ORIG":
            var x = this.toInches(ctx.data_val().children[0].getText());
            var y = this.toInches(ctx.data_val().children[2].getText());
            this.point_stack[0] = [x, y];
            break;
         default:
            area.data[name] = val;
      }
   };

   Listener.prototype.enterCommand = function (ctx) {
      var area = this.area_stack[0];
      var point = this.point_stack[0];
      var new_point = this.movePoint(point, ctx.direction().getText(), this.toInches(ctx.steps().getText()));
      this.point_stack[0] = new_point;

      var convex = false;
      var centralAngle = null;

      var curvature = ctx.curvature();
      if (curvature !== null) {
         convex = curvature.CURVATURE().getText().toUpperCase() === "F";

         if (curvature.NUMBER() != null) {
            centralAngle = parseInt(curvature.NUMBER().getText(), 10);
         }
      }

      area.lines.push(this.createLine(point, this.point_stack[0], centralAngle, convex));
   };

   Listener.prototype.enterPartialCommand = function (ctx) {
      var area = this.area_stack[0];
      var point = this.point_stack[0];
      var new_point = this.movePoint(point, ctx.partialDirection()[0].getText(), this.toInches(ctx.steps()[0].getText())); // Move the point based on the first command.
      new_point = this.movePoint(new_point, ctx.partialDirection()[1].getText(), this.toInches(ctx.steps()[1].getText())); // Move the point again based on the second command.

      this.point_stack[0] = new_point;

      var convex = false;
      var centralAngle = null;

      var curvature = ctx.curvature();
      if (curvature !== null) {
         convex = curvature.CURVATURE().getText().toUpperCase() === "F";

         if (curvature.NUMBER() != null) {
            centralAngle = parseInt(curvature.NUMBER().getText(), 10);
         }
      }

      area.lines.push(this.createLine(point, this.point_stack[0], centralAngle, convex));
   };

   Listener.prototype.movePoint = function (point, direction, distance) {
      switch (direction.toUpperCase()) {
         case "N": return [point[0], point[1] - distance];
         case "E": return [point[0] + distance, point[1]];
         case "S": return [point[0], point[1] + distance];
         case "W": return [point[0] - distance, point[1]];
         case "U": return [point[0], point[1] - distance];
         case "R": return [point[0] + distance, point[1]];
         case "D": return [point[0], point[1] + distance];
         case "L": return [point[0] - distance, point[1]];
      }

      throw new Error("Unrecognized direction \"" + direction + "\".  Expecting N, E, S, W, U, R, D, or L.");
   };

   Listener.prototype.createLine = function (start, end, centralAngle, convex) {
      var line = {
         start: start,
         end: end,
         centralAngle: centralAngle,
         convex: convex,
         CircleSegmentArea: 0,
         Radius: 0
      };

      // Calculate the straight-line length.  This is the actual length for most lines, it is used as the basis of the radius for curved lines.
      line.StraightLength = Math.abs(Math.sqrt(Math.pow(end[0] - start[0], 2) + Math.pow(end[1] - start[1], 2)));
      line.Textpoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

      // Calculate and cache the curved length and circle segment area, if needed.
      if (centralAngle !== null && centralAngle !== 0) {
         var theta = Math.PI * centralAngle / 180.0; // Convert central angle to radians.
         line.Radius = line.StraightLength / (2 * Math.sin(theta / 2));

         // Now that we know the radius, we can get the length arc, from there get the length of the line.  Circumfrence of circle divided by the portion covered by the arc.
         line.Length = (Math.PI * 2 * line.Radius) * (theta / (Math.PI * 2));

         // Calculate the area as total area of circle less the portion not contained in the arc.
         var A = Math.abs(0.5 * Math.pow(line.Radius, 2) * (theta - Math.sin(theta)));

         // Convex curves increase the area.  Concave curves decrease the area.
         line.CircleSegmentArea = convex ? -A : A;

         var cx = null;
         var cy = null;
         var dist = line.StraightLength;
         var radius = line.Radius;
         var startAngle = null;
         var midAngle = null;
         var endAngle = null;
         var convex = line.convex === (centralAngle > 180 ? false : true); // If central angle is >180 then swap treat convex as concave and vis versa.

         if (line.Radius !== 0) {
            // Calculate the circle center point.
            // Right now, the midpoint is the textpoint.
            var mx = line.Textpoint[0];
            var my = line.Textpoint[1];
            var yDist = Math.sqrt(Math.pow(line.Radius, 2) - Math.pow(dist / 2, 2)); // Length of line perpendicular to straight-line between points.

            if (convex) {
               // Convex
               cx = mx - yDist * (start[1] - end[1]) / dist;
               cy = my - yDist * (end[0] - start[0]) / dist;
            } else {
               // Concave
               cx = mx + yDist * (start[1] - end[1]) / dist;
               cy = my + yDist * (end[0] - start[0]) / dist;
            }

            startAngle = Math.atan2(end[1] - cy, end[0] - cx);
            midAngle = Math.atan2(my - cy, mx - cx);
            endAngle = Math.atan2(start[1] - cy, start[0] - cx);

            if (startAngle < 0) startAngle += Math.PI * 2;
            if (midAngle < 0) midAngle += Math.PI * 2;
            if (endAngle < 0) endAngle += Math.PI * 2;

            // Get the position for the text, it is not on the midpoint when dealing with curves.
            var tx = cx + (radius * Math.cos(midAngle));
            var ty = cy + (radius * Math.sin(midAngle));

            // If the angle is greater than 180 then it is inverted.
            if (convex && !line.convex) {
               tx = cx - (radius * Math.cos(midAngle));
               ty = cy - (radius * Math.sin(midAngle));
            } else if (!convex && line.convex) {
               tx = cx - (radius * Math.cos(midAngle));
               ty = cy - (radius * Math.sin(midAngle));
            }

            // Swap the start and end angles if concave.
            if (!line.convex) {
               var temp = startAngle;
               startAngle = endAngle;
               endAngle = temp;
            }

            line.StartAngle = startAngle;
            line.EndAngle = endAngle;
            line.Centerpoint = [cx, cy];
            line.Textpoint = [tx, ty]; // Change the textpoint because the text will appear along the arc.
         }
      } else {
         line.Length = line.StraightLength;
      }

      return line;
   };

   Listener.prototype.closeArea = function (area) {
      if (area.lines.length > 1) {
         // Does the area first point match the last point?
         var start = area.lines[0].start;
         var end = area.lines[area.lines.length - 1].end;
         if (start[0] !== end[0] || start[1] !== end[1]) {
            this.errors.push('Area ' + area.cd + ' is not closed.');
         }

         // Calculate the area's centroid, area, and perimeter.
         var areaPrime = 0;
         var centroidPrimeX = 0;
         var centroidPrimeY = 0;
         var perimeter = 0;

         // Notice: Do not scan the last point.
         for (var i = 0; i < area.lines.length; i++) {
            var line = area.lines[i];

            var p1, p2;

            // For added precision with circle areas, go from the start to TextPoint then from TextPoint to end.
            for (var j = 0; j < 2; j++) {
               if (j == 0) {
                  p1 = line.start;
                  p2 = line.Textpoint;
               } else {
                  p1 = line.Textpoint;
                  p2 = line.end;
               }

               var normalizer = (p1[0] * p2[1]) - (p2[0] * p1[1]); // This normalizer is part of the area and the centroid calculation.
               areaPrime += normalizer;
               //areaPrime += 2 * line.CircleSegmentArea; // Multiply by two, since we will be dividing by two.
               centroidPrimeX += (p1[0] + p2[0]) * normalizer;
               centroidPrimeY += (p1[1] + p2[1]) * normalizer;
            }

            perimeter += line.Length;
         }

         var sqFt = 0.5 * areaPrime;
         var centroidNormalizer = 1 / (6 * sqFt);
         var centroidX = centroidPrimeX * centroidNormalizer;
         var centroidY = centroidPrimeY * centroidNormalizer;

         area.area_inches = Math.round(Math.abs(sqFt), 0);
         area.centroid = [centroidX, centroidY];
         area.perimeter_inches = Math.round(perimeter, 0);
      }
   };

   Listener.prototype.toInches = function (ft) {
      // The fractional part is in 12ths, not tenths.
      // Basically it is feet.inches where inches is between 0 and 11.
      var vals = ft.split('.');
      var px = parseFloat(vals[0]) * 12;
      if (vals.length > 1) {
         if (px < 0) {
            px -= parseFloat(vals[1]);
         } else {
            px += parseFloat(vals[1]);
         }
      }

      return px;
   };

   function SketchErrorListener() {
      antlr4.error.ErrorListener.call(this);
      return this;
   };

   SketchErrorListener.prototype = Object.create(antlr4.error.ErrorListener.prototype);
   SketchErrorListener.prototype.constructor = SketchErrorListener;
   SketchErrorListener.INSTANCE = new SketchErrorListener();

   SketchErrorListener.prototype.syntaxError = function (recognizer, offendingSymbol, line, column, msg, e) {
      this.errors.push("line " + line + ":" + column + " " + msg);
   };

   SketchErrorListener.prototype.initialize = function (errors) {
      this.errors = errors;
   };

}());