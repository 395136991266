// Generated from Sketch.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by SketchParser.
function SketchListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

SketchListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
SketchListener.prototype.constructor = SketchListener;

// Enter a parse tree produced by SketchParser#sketch.
SketchListener.prototype.enterSketch = function(ctx) {
};

// Exit a parse tree produced by SketchParser#sketch.
SketchListener.prototype.exitSketch = function(ctx) {
};


// Enter a parse tree produced by SketchParser#area.
SketchListener.prototype.enterArea = function(ctx) {
};

// Exit a parse tree produced by SketchParser#area.
SketchListener.prototype.exitArea = function(ctx) {
};


// Enter a parse tree produced by SketchParser#name.
SketchListener.prototype.enterName = function(ctx) {
};

// Exit a parse tree produced by SketchParser#name.
SketchListener.prototype.exitName = function(ctx) {
};


// Enter a parse tree produced by SketchParser#origin.
SketchListener.prototype.enterOrigin = function(ctx) {
};

// Exit a parse tree produced by SketchParser#origin.
SketchListener.prototype.exitOrigin = function(ctx) {
};


// Enter a parse tree produced by SketchParser#multiplier.
SketchListener.prototype.enterMultiplier = function(ctx) {
};

// Exit a parse tree produced by SketchParser#multiplier.
SketchListener.prototype.exitMultiplier = function(ctx) {
};


// Enter a parse tree produced by SketchParser#label.
SketchListener.prototype.enterLabel = function(ctx) {
};

// Exit a parse tree produced by SketchParser#label.
SketchListener.prototype.exitLabel = function(ctx) {
};


// Enter a parse tree produced by SketchParser#data.
SketchListener.prototype.enterData = function(ctx) {
};

// Exit a parse tree produced by SketchParser#data.
SketchListener.prototype.exitData = function(ctx) {
};


// Enter a parse tree produced by SketchParser#datum.
SketchListener.prototype.enterDatum = function(ctx) {
};

// Exit a parse tree produced by SketchParser#datum.
SketchListener.prototype.exitDatum = function(ctx) {
};


// Enter a parse tree produced by SketchParser#data_val.
SketchListener.prototype.enterData_val = function(ctx) {
};

// Exit a parse tree produced by SketchParser#data_val.
SketchListener.prototype.exitData_val = function(ctx) {
};


// Enter a parse tree produced by SketchParser#segments.
SketchListener.prototype.enterSegments = function(ctx) {
};

// Exit a parse tree produced by SketchParser#segments.
SketchListener.prototype.exitSegments = function(ctx) {
};


// Enter a parse tree produced by SketchParser#command.
SketchListener.prototype.enterCommand = function(ctx) {
};

// Exit a parse tree produced by SketchParser#command.
SketchListener.prototype.exitCommand = function(ctx) {
};


// Enter a parse tree produced by SketchParser#partialCommand.
SketchListener.prototype.enterPartialCommand = function(ctx) {
};

// Exit a parse tree produced by SketchParser#partialCommand.
SketchListener.prototype.exitPartialCommand = function(ctx) {
};


// Enter a parse tree produced by SketchParser#direction.
SketchListener.prototype.enterDirection = function(ctx) {
};

// Exit a parse tree produced by SketchParser#direction.
SketchListener.prototype.exitDirection = function(ctx) {
};


// Enter a parse tree produced by SketchParser#partialDirection.
SketchListener.prototype.enterPartialDirection = function(ctx) {
};

// Exit a parse tree produced by SketchParser#partialDirection.
SketchListener.prototype.exitPartialDirection = function(ctx) {
};


// Enter a parse tree produced by SketchParser#steps.
SketchListener.prototype.enterSteps = function(ctx) {
};

// Exit a parse tree produced by SketchParser#steps.
SketchListener.prototype.exitSteps = function(ctx) {
};


// Enter a parse tree produced by SketchParser#curvature.
SketchListener.prototype.enterCurvature = function(ctx) {
};

// Exit a parse tree produced by SketchParser#curvature.
SketchListener.prototype.exitCurvature = function(ctx) {
};



exports.SketchListener = SketchListener;