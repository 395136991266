// Generated from Sketch.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');
var SketchListener = require('./SketchListener').SketchListener;
var grammarFileName = "Sketch.g4";

var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\u0012\u0082\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004",
    "\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007",
    "\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004\f\t\f",
    "\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010\t\u0010",
    "\u0004\u0011\t\u0011\u0003\u0002\u0006\u0002$\n\u0002\r\u0002\u000e",
    "\u0002%\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003",
    "\u0005\u0003-\n\u0003\u0003\u0003\u0003\u0003\u0005\u00031\n\u0003\u0003",
    "\u0003\u0003\u0003\u0005\u00035\n\u0003\u0003\u0003\u0005\u00038\n\u0003",
    "\u0003\u0003\u0005\u0003;\n\u0003\u0003\u0003\u0005\u0003>\n\u0003\u0003",
    "\u0004\u0006\u0004A\n\u0004\r\u0004\u000e\u0004B\u0003\u0005\u0003\u0005",
    "\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006",
    "\u0003\u0006\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0006\bR\n\b\r",
    "\b\u000e\bS\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0005\t",
    "\\\n\t\u0003\n\u0006\n_\n\n\r\n\u000e\n`\u0003\u000b\u0003\u000b\u0003",
    "\u000b\u0006\u000bf\n\u000b\r\u000b\u000e\u000bg\u0003\f\u0003\f\u0003",
    "\f\u0003\f\u0005\fn\n\f\u0003\r\u0003\r\u0003\r\u0003\r\u0003\r\u0003",
    "\r\u0005\rv\n\r\u0003\u000e\u0003\u000e\u0003\u000f\u0003\u000f\u0003",
    "\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0005\u0011\u0080\n\u0011",
    "\u0003\u0011\u0002\u0002\u0012\u0002\u0004\u0006\b\n\f\u000e\u0010\u0012",
    "\u0014\u0016\u0018\u001a\u001c\u001e \u0002\u0004\u0005\u0002\u0003",
    "\u0005\u0007\u0007\t\t\u0003\u0002\u000b\f\u0002\u0082\u0002#\u0003",
    "\u0002\u0002\u0002\u0004*\u0003\u0002\u0002\u0002\u0006@\u0003\u0002",
    "\u0002\u0002\bD\u0003\u0002\u0002\u0002\nJ\u0003\u0002\u0002\u0002\f",
    "M\u0003\u0002\u0002\u0002\u000eO\u0003\u0002\u0002\u0002\u0010W\u0003",
    "\u0002\u0002\u0002\u0012^\u0003\u0002\u0002\u0002\u0014e\u0003\u0002",
    "\u0002\u0002\u0016i\u0003\u0002\u0002\u0002\u0018o\u0003\u0002\u0002",
    "\u0002\u001aw\u0003\u0002\u0002\u0002\u001cy\u0003\u0002\u0002\u0002",
    "\u001e{\u0003\u0002\u0002\u0002 }\u0003\u0002\u0002\u0002\"$\u0005\u0004",
    "\u0003\u0002#\"\u0003\u0002\u0002\u0002$%\u0003\u0002\u0002\u0002%#",
    "\u0003\u0002\u0002\u0002%&\u0003\u0002\u0002\u0002&\'\u0003\u0002\u0002",
    "\u0002\'(\u0007\u0010\u0002\u0002()\u0007\u0002\u0002\u0003)\u0003\u0003",
    "\u0002\u0002\u0002*,\u0005\u0006\u0004\u0002+-\u0005\b\u0005\u0002,",
    "+\u0003\u0002\u0002\u0002,-\u0003\u0002\u0002\u0002-.\u0003\u0002\u0002",
    "\u0002.0\u0007\u0006\u0002\u0002/1\u0005\u000e\b\u00020/\u0003\u0002",
    "\u0002\u000201\u0003\u0002\u0002\u000214\u0003\u0002\u0002\u000225\u0007",
    "\t\u0002\u000235\u0005\u0014\u000b\u000242\u0003\u0002\u0002\u00024",
    "3\u0003\u0002\u0002\u000257\u0003\u0002\u0002\u000268\u0007\u000f\u0002",
    "\u000276\u0003\u0002\u0002\u000278\u0003\u0002\u0002\u00028:\u0003\u0002",
    "\u0002\u00029;\u0005\n\u0006\u0002:9\u0003\u0002\u0002\u0002:;\u0003",
    "\u0002\u0002\u0002;=\u0003\u0002\u0002\u0002<>\u0005\f\u0007\u0002=",
    "<\u0003\u0002\u0002\u0002=>\u0003\u0002\u0002\u0002>\u0005\u0003\u0002",
    "\u0002\u0002?A\t\u0002\u0002\u0002@?\u0003\u0002\u0002\u0002AB\u0003",
    "\u0002\u0002\u0002B@\u0003\u0002\u0002\u0002BC\u0003\u0002\u0002\u0002",
    "C\u0007\u0003\u0002\u0002\u0002DE\u0007\r\u0002\u0002EF\u0007\t\u0002",
    "\u0002FG\u0007\u000e\u0002\u0002GH\u0007\t\u0002\u0002HI\u0007\r\u0002",
    "\u0002I\t\u0003\u0002\u0002\u0002JK\u0007\u0011\u0002\u0002KL\u0007",
    "\t\u0002\u0002L\u000b\u0003\u0002\u0002\u0002MN\u0007\u0012\u0002\u0002",
    "N\r\u0003\u0002\u0002\u0002OQ\u0007\n\u0002\u0002PR\u0005\u0010\t\u0002",
    "QP\u0003\u0002\u0002\u0002RS\u0003\u0002\u0002\u0002SQ\u0003\u0002\u0002",
    "\u0002ST\u0003\u0002\u0002\u0002TU\u0003\u0002\u0002\u0002UV\u0007\u000b",
    "\u0002\u0002V\u000f\u0003\u0002\u0002\u0002WX\u0005\u0006\u0004\u0002",
    "XY\u0007\u0006\u0002\u0002Y[\u0005\u0012\n\u0002Z\\\u0007\f\u0002\u0002",
    "[Z\u0003\u0002\u0002\u0002[\\\u0003\u0002\u0002\u0002\\\u0011\u0003",
    "\u0002\u0002\u0002]_\n\u0003\u0002\u0002^]\u0003\u0002\u0002\u0002_",
    "`\u0003\u0002\u0002\u0002`^\u0003\u0002\u0002\u0002`a\u0003\u0002\u0002",
    "\u0002a\u0013\u0003\u0002\u0002\u0002bf\u0005\u0016\f\u0002cf\u0005",
    "\u0018\r\u0002df\u0005\u0004\u0003\u0002eb\u0003\u0002\u0002\u0002e",
    "c\u0003\u0002\u0002\u0002ed\u0003\u0002\u0002\u0002fg\u0003\u0002\u0002",
    "\u0002ge\u0003\u0002\u0002\u0002gh\u0003\u0002\u0002\u0002h\u0015\u0003",
    "\u0002\u0002\u0002ij\u0005\u001a\u000e\u0002jk\u0005\u001e\u0010\u0002",
    "km\u0003\u0002\u0002\u0002ln\u0005 \u0011\u0002ml\u0003\u0002\u0002",
    "\u0002mn\u0003\u0002\u0002\u0002n\u0017\u0003\u0002\u0002\u0002op\u0005",
    "\u001c\u000f\u0002pq\u0005\u001e\u0010\u0002qr\u0005\u001c\u000f\u0002",
    "rs\u0005\u001e\u0010\u0002su\u0003\u0002\u0002\u0002tv\u0005 \u0011",
    "\u0002ut\u0003\u0002\u0002\u0002uv\u0003\u0002\u0002\u0002v\u0019\u0003",
    "\u0002\u0002\u0002wx\u0007\u0003\u0002\u0002x\u001b\u0003\u0002\u0002",
    "\u0002yz\u0007\u0004\u0002\u0002z\u001d\u0003\u0002\u0002\u0002{|\u0007",
    "\t\u0002\u0002|\u001f\u0003\u0002\u0002\u0002}\u007f\u0007\u0005\u0002",
    "\u0002~\u0080\u0007\t\u0002\u0002\u007f~\u0003\u0002\u0002\u0002\u007f",
    "\u0080\u0003\u0002\u0002\u0002\u0080!\u0003\u0002\u0002\u0002\u0012",
    "%,047:=BS[`egmu\u007f"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, null, null, null, "'='", null, null, null, "'['", 
                     "']'", "';'", "':'", "','", "'$'", "'.'", "'*'" ];

var symbolicNames = [ null, "DIRECTION", "PARTIAL_DIRECTION", "CURVATURE", 
                      "EQUAL", "WORD", "WHITESPACE", "NUMBER", "START_DATA", 
                      "END_DATA", "SEMICOLON", "COLON", "COMMA", "END_AREA", 
                      "END", "STAR", "LABEL" ];

var ruleNames =  [ "sketch", "area", "name", "origin", "multiplier", "label", 
                   "data", "datum", "data_val", "segments", "command", "partialCommand", 
                   "direction", "partialDirection", "steps", "curvature" ];

function SketchParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

SketchParser.prototype = Object.create(antlr4.Parser.prototype);
SketchParser.prototype.constructor = SketchParser;

Object.defineProperty(SketchParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

SketchParser.EOF = antlr4.Token.EOF;
SketchParser.DIRECTION = 1;
SketchParser.PARTIAL_DIRECTION = 2;
SketchParser.CURVATURE = 3;
SketchParser.EQUAL = 4;
SketchParser.WORD = 5;
SketchParser.WHITESPACE = 6;
SketchParser.NUMBER = 7;
SketchParser.START_DATA = 8;
SketchParser.END_DATA = 9;
SketchParser.SEMICOLON = 10;
SketchParser.COLON = 11;
SketchParser.COMMA = 12;
SketchParser.END_AREA = 13;
SketchParser.END = 14;
SketchParser.STAR = 15;
SketchParser.LABEL = 16;

SketchParser.RULE_sketch = 0;
SketchParser.RULE_area = 1;
SketchParser.RULE_name = 2;
SketchParser.RULE_origin = 3;
SketchParser.RULE_multiplier = 4;
SketchParser.RULE_label = 5;
SketchParser.RULE_data = 6;
SketchParser.RULE_datum = 7;
SketchParser.RULE_data_val = 8;
SketchParser.RULE_segments = 9;
SketchParser.RULE_command = 10;
SketchParser.RULE_partialCommand = 11;
SketchParser.RULE_direction = 12;
SketchParser.RULE_partialDirection = 13;
SketchParser.RULE_steps = 14;
SketchParser.RULE_curvature = 15;

function SketchContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_sketch;
    return this;
}

SketchContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SketchContext.prototype.constructor = SketchContext;

SketchContext.prototype.END = function() {
    return this.getToken(SketchParser.END, 0);
};

SketchContext.prototype.EOF = function() {
    return this.getToken(SketchParser.EOF, 0);
};

SketchContext.prototype.area = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(AreaContext);
    } else {
        return this.getTypedRuleContext(AreaContext,i);
    }
};

SketchContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterSketch(this);
	}
};

SketchContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitSketch(this);
	}
};




SketchParser.SketchContext = SketchContext;

SketchParser.prototype.sketch = function() {

    var localctx = new SketchContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, SketchParser.RULE_sketch);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 33; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 32;
            this.area();
            this.state = 35; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SketchParser.DIRECTION) | (1 << SketchParser.PARTIAL_DIRECTION) | (1 << SketchParser.CURVATURE) | (1 << SketchParser.WORD) | (1 << SketchParser.NUMBER))) !== 0));
        this.state = 37;
        this.match(SketchParser.END);
        this.state = 38;
        this.match(SketchParser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function AreaContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_area;
    return this;
}

AreaContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
AreaContext.prototype.constructor = AreaContext;

AreaContext.prototype.name = function() {
    return this.getTypedRuleContext(NameContext,0);
};

AreaContext.prototype.EQUAL = function() {
    return this.getToken(SketchParser.EQUAL, 0);
};

AreaContext.prototype.NUMBER = function() {
    return this.getToken(SketchParser.NUMBER, 0);
};

AreaContext.prototype.segments = function() {
    return this.getTypedRuleContext(SegmentsContext,0);
};

AreaContext.prototype.origin = function() {
    return this.getTypedRuleContext(OriginContext,0);
};

AreaContext.prototype.data = function() {
    return this.getTypedRuleContext(DataContext,0);
};

AreaContext.prototype.END_AREA = function() {
    return this.getToken(SketchParser.END_AREA, 0);
};

AreaContext.prototype.multiplier = function() {
    return this.getTypedRuleContext(MultiplierContext,0);
};

AreaContext.prototype.label = function() {
    return this.getTypedRuleContext(LabelContext,0);
};

AreaContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterArea(this);
	}
};

AreaContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitArea(this);
	}
};




SketchParser.AreaContext = AreaContext;

SketchParser.prototype.area = function() {

    var localctx = new AreaContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, SketchParser.RULE_area);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 40;
        this.name();
        this.state = 42;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===SketchParser.COLON) {
            this.state = 41;
            this.origin();
        }

        this.state = 44;
        this.match(SketchParser.EQUAL);
        this.state = 46;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===SketchParser.START_DATA) {
            this.state = 45;
            this.data();
        }

        this.state = 50;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,3,this._ctx);
        switch(la_) {
        case 1:
            this.state = 48;
            this.match(SketchParser.NUMBER);
            break;

        case 2:
            this.state = 49;
            this.segments();
            break;

        }
        this.state = 53;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,4,this._ctx);
        if(la_===1) {
            this.state = 52;
            this.match(SketchParser.END_AREA);

        }
        this.state = 56;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,5,this._ctx);
        if(la_===1) {
            this.state = 55;
            this.multiplier();

        }
        this.state = 59;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,6,this._ctx);
        if(la_===1) {
            this.state = 58;
            this.label();

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function NameContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_name;
    return this;
}

NameContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NameContext.prototype.constructor = NameContext;

NameContext.prototype.WORD = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.WORD);
    } else {
        return this.getToken(SketchParser.WORD, i);
    }
};


NameContext.prototype.NUMBER = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.NUMBER);
    } else {
        return this.getToken(SketchParser.NUMBER, i);
    }
};


NameContext.prototype.DIRECTION = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.DIRECTION);
    } else {
        return this.getToken(SketchParser.DIRECTION, i);
    }
};


NameContext.prototype.PARTIAL_DIRECTION = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.PARTIAL_DIRECTION);
    } else {
        return this.getToken(SketchParser.PARTIAL_DIRECTION, i);
    }
};


NameContext.prototype.CURVATURE = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.CURVATURE);
    } else {
        return this.getToken(SketchParser.CURVATURE, i);
    }
};


NameContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterName(this);
	}
};

NameContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitName(this);
	}
};




SketchParser.NameContext = NameContext;

SketchParser.prototype.name = function() {

    var localctx = new NameContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, SketchParser.RULE_name);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 62; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 61;
            _la = this._input.LA(1);
            if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SketchParser.DIRECTION) | (1 << SketchParser.PARTIAL_DIRECTION) | (1 << SketchParser.CURVATURE) | (1 << SketchParser.WORD) | (1 << SketchParser.NUMBER))) !== 0))) {
            this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 64; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SketchParser.DIRECTION) | (1 << SketchParser.PARTIAL_DIRECTION) | (1 << SketchParser.CURVATURE) | (1 << SketchParser.WORD) | (1 << SketchParser.NUMBER))) !== 0));
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function OriginContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_origin;
    return this;
}

OriginContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
OriginContext.prototype.constructor = OriginContext;

OriginContext.prototype.COLON = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.COLON);
    } else {
        return this.getToken(SketchParser.COLON, i);
    }
};


OriginContext.prototype.NUMBER = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.NUMBER);
    } else {
        return this.getToken(SketchParser.NUMBER, i);
    }
};


OriginContext.prototype.COMMA = function() {
    return this.getToken(SketchParser.COMMA, 0);
};

OriginContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterOrigin(this);
	}
};

OriginContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitOrigin(this);
	}
};




SketchParser.OriginContext = OriginContext;

SketchParser.prototype.origin = function() {

    var localctx = new OriginContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, SketchParser.RULE_origin);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 66;
        this.match(SketchParser.COLON);
        this.state = 67;
        this.match(SketchParser.NUMBER);
        this.state = 68;
        this.match(SketchParser.COMMA);
        this.state = 69;
        this.match(SketchParser.NUMBER);
        this.state = 70;
        this.match(SketchParser.COLON);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function MultiplierContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_multiplier;
    return this;
}

MultiplierContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
MultiplierContext.prototype.constructor = MultiplierContext;

MultiplierContext.prototype.STAR = function() {
    return this.getToken(SketchParser.STAR, 0);
};

MultiplierContext.prototype.NUMBER = function() {
    return this.getToken(SketchParser.NUMBER, 0);
};

MultiplierContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterMultiplier(this);
	}
};

MultiplierContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitMultiplier(this);
	}
};




SketchParser.MultiplierContext = MultiplierContext;

SketchParser.prototype.multiplier = function() {

    var localctx = new MultiplierContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, SketchParser.RULE_multiplier);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 72;
        this.match(SketchParser.STAR);
        this.state = 73;
        this.match(SketchParser.NUMBER);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function LabelContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_label;
    return this;
}

LabelContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
LabelContext.prototype.constructor = LabelContext;

LabelContext.prototype.LABEL = function() {
    return this.getToken(SketchParser.LABEL, 0);
};

LabelContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterLabel(this);
	}
};

LabelContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitLabel(this);
	}
};




SketchParser.LabelContext = LabelContext;

SketchParser.prototype.label = function() {

    var localctx = new LabelContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, SketchParser.RULE_label);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 75;
        this.match(SketchParser.LABEL);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DataContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_data;
    return this;
}

DataContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DataContext.prototype.constructor = DataContext;

DataContext.prototype.START_DATA = function() {
    return this.getToken(SketchParser.START_DATA, 0);
};

DataContext.prototype.END_DATA = function() {
    return this.getToken(SketchParser.END_DATA, 0);
};

DataContext.prototype.datum = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(DatumContext);
    } else {
        return this.getTypedRuleContext(DatumContext,i);
    }
};

DataContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterData(this);
	}
};

DataContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitData(this);
	}
};




SketchParser.DataContext = DataContext;

SketchParser.prototype.data = function() {

    var localctx = new DataContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, SketchParser.RULE_data);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 77;
        this.match(SketchParser.START_DATA);
        this.state = 79; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 78;
            this.datum();
            this.state = 81; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << SketchParser.DIRECTION) | (1 << SketchParser.PARTIAL_DIRECTION) | (1 << SketchParser.CURVATURE) | (1 << SketchParser.WORD) | (1 << SketchParser.NUMBER))) !== 0));
        this.state = 83;
        this.match(SketchParser.END_DATA);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DatumContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_datum;
    return this;
}

DatumContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DatumContext.prototype.constructor = DatumContext;

DatumContext.prototype.name = function() {
    return this.getTypedRuleContext(NameContext,0);
};

DatumContext.prototype.EQUAL = function() {
    return this.getToken(SketchParser.EQUAL, 0);
};

DatumContext.prototype.data_val = function() {
    return this.getTypedRuleContext(Data_valContext,0);
};

DatumContext.prototype.SEMICOLON = function() {
    return this.getToken(SketchParser.SEMICOLON, 0);
};

DatumContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterDatum(this);
	}
};

DatumContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitDatum(this);
	}
};




SketchParser.DatumContext = DatumContext;

SketchParser.prototype.datum = function() {

    var localctx = new DatumContext(this, this._ctx, this.state);
    this.enterRule(localctx, 14, SketchParser.RULE_datum);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 85;
        this.name();
        this.state = 86;
        this.match(SketchParser.EQUAL);
        this.state = 87;
        this.data_val();
        this.state = 89;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if(_la===SketchParser.SEMICOLON) {
            this.state = 88;
            this.match(SketchParser.SEMICOLON);
        }

    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function Data_valContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_data_val;
    return this;
}

Data_valContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
Data_valContext.prototype.constructor = Data_valContext;

Data_valContext.prototype.SEMICOLON = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.SEMICOLON);
    } else {
        return this.getToken(SketchParser.SEMICOLON, i);
    }
};


Data_valContext.prototype.END_DATA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(SketchParser.END_DATA);
    } else {
        return this.getToken(SketchParser.END_DATA, i);
    }
};


Data_valContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterData_val(this);
	}
};

Data_valContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitData_val(this);
	}
};




SketchParser.Data_valContext = Data_valContext;

SketchParser.prototype.data_val = function() {

    var localctx = new Data_valContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, SketchParser.RULE_data_val);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 92; 
        this._errHandler.sync(this);
        var _alt = 1;
        do {
        	switch (_alt) {
        	case 1:
        		this.state = 91;
        		_la = this._input.LA(1);
        		if(_la<=0 || _la===SketchParser.END_DATA || _la===SketchParser.SEMICOLON) {
        		this._errHandler.recoverInline(this);
        		}
        		else {
        			this._errHandler.reportMatch(this);
        		    this.consume();
        		}
        		break;
        	default:
        		throw new antlr4.error.NoViableAltException(this);
        	}
        	this.state = 94; 
        	this._errHandler.sync(this);
        	_alt = this._interp.adaptivePredict(this._input,10, this._ctx);
        } while ( _alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER );
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function SegmentsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_segments;
    return this;
}

SegmentsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
SegmentsContext.prototype.constructor = SegmentsContext;

SegmentsContext.prototype.command = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(CommandContext);
    } else {
        return this.getTypedRuleContext(CommandContext,i);
    }
};

SegmentsContext.prototype.partialCommand = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PartialCommandContext);
    } else {
        return this.getTypedRuleContext(PartialCommandContext,i);
    }
};

SegmentsContext.prototype.area = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(AreaContext);
    } else {
        return this.getTypedRuleContext(AreaContext,i);
    }
};

SegmentsContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterSegments(this);
	}
};

SegmentsContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitSegments(this);
	}
};




SketchParser.SegmentsContext = SegmentsContext;

SketchParser.prototype.segments = function() {

    var localctx = new SegmentsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 18, SketchParser.RULE_segments);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 99; 
        this._errHandler.sync(this);
        var _alt = 1;
        do {
        	switch (_alt) {
        	case 1:
        		this.state = 99;
        		this._errHandler.sync(this);
        		var la_ = this._interp.adaptivePredict(this._input,11,this._ctx);
        		switch(la_) {
        		case 1:
        		    this.state = 96;
        		    this.command();
        		    break;

        		case 2:
        		    this.state = 97;
        		    this.partialCommand();
        		    break;

        		case 3:
        		    this.state = 98;
        		    this.area();
        		    break;

        		}
        		break;
        	default:
        		throw new antlr4.error.NoViableAltException(this);
        	}
        	this.state = 101; 
        	this._errHandler.sync(this);
        	_alt = this._interp.adaptivePredict(this._input,12, this._ctx);
        } while ( _alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER );
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function CommandContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_command;
    return this;
}

CommandContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CommandContext.prototype.constructor = CommandContext;

CommandContext.prototype.direction = function() {
    return this.getTypedRuleContext(DirectionContext,0);
};

CommandContext.prototype.steps = function() {
    return this.getTypedRuleContext(StepsContext,0);
};

CommandContext.prototype.curvature = function() {
    return this.getTypedRuleContext(CurvatureContext,0);
};

CommandContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterCommand(this);
	}
};

CommandContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitCommand(this);
	}
};




SketchParser.CommandContext = CommandContext;

SketchParser.prototype.command = function() {

    var localctx = new CommandContext(this, this._ctx, this.state);
    this.enterRule(localctx, 20, SketchParser.RULE_command);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 103;
        this.direction();
        this.state = 104;
        this.steps();
        this.state = 107;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,13,this._ctx);
        if(la_===1) {
            this.state = 106;
            this.curvature();

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PartialCommandContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_partialCommand;
    return this;
}

PartialCommandContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PartialCommandContext.prototype.constructor = PartialCommandContext;

PartialCommandContext.prototype.partialDirection = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(PartialDirectionContext);
    } else {
        return this.getTypedRuleContext(PartialDirectionContext,i);
    }
};

PartialCommandContext.prototype.steps = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(StepsContext);
    } else {
        return this.getTypedRuleContext(StepsContext,i);
    }
};

PartialCommandContext.prototype.curvature = function() {
    return this.getTypedRuleContext(CurvatureContext,0);
};

PartialCommandContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterPartialCommand(this);
	}
};

PartialCommandContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitPartialCommand(this);
	}
};




SketchParser.PartialCommandContext = PartialCommandContext;

SketchParser.prototype.partialCommand = function() {

    var localctx = new PartialCommandContext(this, this._ctx, this.state);
    this.enterRule(localctx, 22, SketchParser.RULE_partialCommand);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 109;
        this.partialDirection();
        this.state = 110;
        this.steps();
        this.state = 111;
        this.partialDirection();
        this.state = 112;
        this.steps();
        this.state = 115;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,14,this._ctx);
        if(la_===1) {
            this.state = 114;
            this.curvature();

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function DirectionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_direction;
    return this;
}

DirectionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
DirectionContext.prototype.constructor = DirectionContext;

DirectionContext.prototype.DIRECTION = function() {
    return this.getToken(SketchParser.DIRECTION, 0);
};

DirectionContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterDirection(this);
	}
};

DirectionContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitDirection(this);
	}
};




SketchParser.DirectionContext = DirectionContext;

SketchParser.prototype.direction = function() {

    var localctx = new DirectionContext(this, this._ctx, this.state);
    this.enterRule(localctx, 24, SketchParser.RULE_direction);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 117;
        this.match(SketchParser.DIRECTION);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function PartialDirectionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_partialDirection;
    return this;
}

PartialDirectionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
PartialDirectionContext.prototype.constructor = PartialDirectionContext;

PartialDirectionContext.prototype.PARTIAL_DIRECTION = function() {
    return this.getToken(SketchParser.PARTIAL_DIRECTION, 0);
};

PartialDirectionContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterPartialDirection(this);
	}
};

PartialDirectionContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitPartialDirection(this);
	}
};




SketchParser.PartialDirectionContext = PartialDirectionContext;

SketchParser.prototype.partialDirection = function() {

    var localctx = new PartialDirectionContext(this, this._ctx, this.state);
    this.enterRule(localctx, 26, SketchParser.RULE_partialDirection);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 119;
        this.match(SketchParser.PARTIAL_DIRECTION);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function StepsContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_steps;
    return this;
}

StepsContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StepsContext.prototype.constructor = StepsContext;

StepsContext.prototype.NUMBER = function() {
    return this.getToken(SketchParser.NUMBER, 0);
};

StepsContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterSteps(this);
	}
};

StepsContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitSteps(this);
	}
};




SketchParser.StepsContext = StepsContext;

SketchParser.prototype.steps = function() {

    var localctx = new StepsContext(this, this._ctx, this.state);
    this.enterRule(localctx, 28, SketchParser.RULE_steps);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 121;
        this.match(SketchParser.NUMBER);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};

function CurvatureContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = SketchParser.RULE_curvature;
    return this;
}

CurvatureContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
CurvatureContext.prototype.constructor = CurvatureContext;

CurvatureContext.prototype.CURVATURE = function() {
    return this.getToken(SketchParser.CURVATURE, 0);
};

CurvatureContext.prototype.NUMBER = function() {
    return this.getToken(SketchParser.NUMBER, 0);
};

CurvatureContext.prototype.enterRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.enterCurvature(this);
	}
};

CurvatureContext.prototype.exitRule = function(listener) {
    if(listener instanceof SketchListener ) {
        listener.exitCurvature(this);
	}
};




SketchParser.CurvatureContext = CurvatureContext;

SketchParser.prototype.curvature = function() {

    var localctx = new CurvatureContext(this, this._ctx, this.state);
    this.enterRule(localctx, 30, SketchParser.RULE_curvature);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 123;
        this.match(SketchParser.CURVATURE);
        this.state = 125;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,15,this._ctx);
        if(la_===1) {
            this.state = 124;
            this.match(SketchParser.NUMBER);

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


exports.SketchParser = SketchParser;
