// Generated from Sketch.g4 by ANTLR 4.7.1
// jshint ignore: start
var antlr4 = require('antlr4/index');


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u0012q\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003",
    "\u0003\u0004\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006",
    "\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0006",
    "\t;\n\t\r\t\u000e\t<\u0003\n\u0003\n\u0005\nA\n\n\u0003\n\u0003\n\u0005",
    "\nE\n\n\u0003\n\u0003\n\u0003\u000b\u0005\u000bJ\n\u000b\u0003\u000b",
    "\u0006\u000bM\n\u000b\r\u000b\u000e\u000bN\u0003\u000b\u0003\u000b\u0006",
    "\u000bS\n\u000b\r\u000b\u000e\u000bT\u0005\u000bW\n\u000b\u0003\f\u0003",
    "\f\u0003\r\u0003\r\u0003\u000e\u0003\u000e\u0003\u000f\u0003\u000f\u0003",
    "\u0010\u0003\u0010\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003",
    "\u0013\u0003\u0013\u0003\u0014\u0003\u0014\u0007\u0014k\n\u0014\f\u0014",
    "\u000e\u0014n\u000b\u0014\u0003\u0014\u0003\u0014\u0003l\u0002\u0015",
    "\u0003\u0002\u0005\u0002\u0007\u0002\t\u0003\u000b\u0004\r\u0005\u000f",
    "\u0006\u0011\u0007\u0013\b\u0015\t\u0017\n\u0019\u000b\u001b\f\u001d",
    "\r\u001f\u000e!\u000f#\u0010%\u0011\'\u0012\u0003\u0002\n\u0003\u0002",
    "c|\u0003\u0002C\\\u0003\u00022;\u0006\u0002GGPPUUYY\u0006\u0002FFNN",
    "TTWW\u0003\u0002HI\u0004\u0002\u000b\u000b\"\"\u0003\u000200\u0002x",
    "\u0002\t\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002",
    "\u0002\r\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002",
    "\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002",
    "\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002",
    "\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002\u0002",
    "\u0002\u001d\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002\u0002",
    "\u0002!\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002\u0002",
    "%\u0003\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0003)\u0003",
    "\u0002\u0002\u0002\u0005+\u0003\u0002\u0002\u0002\u0007-\u0003\u0002",
    "\u0002\u0002\t/\u0003\u0002\u0002\u0002\u000b1\u0003\u0002\u0002\u0002",
    "\r3\u0003\u0002\u0002\u0002\u000f5\u0003\u0002\u0002\u0002\u0011:\u0003",
    "\u0002\u0002\u0002\u0013D\u0003\u0002\u0002\u0002\u0015I\u0003\u0002",
    "\u0002\u0002\u0017X\u0003\u0002\u0002\u0002\u0019Z\u0003\u0002\u0002",
    "\u0002\u001b\\\u0003\u0002\u0002\u0002\u001d^\u0003\u0002\u0002\u0002",
    "\u001f`\u0003\u0002\u0002\u0002!b\u0003\u0002\u0002\u0002#d\u0003\u0002",
    "\u0002\u0002%f\u0003\u0002\u0002\u0002\'h\u0003\u0002\u0002\u0002)*",
    "\t\u0002\u0002\u0002*\u0004\u0003\u0002\u0002\u0002+,\t\u0003\u0002",
    "\u0002,\u0006\u0003\u0002\u0002\u0002-.\t\u0004\u0002\u0002.\b\u0003",
    "\u0002\u0002\u0002/0\t\u0005\u0002\u00020\n\u0003\u0002\u0002\u0002",
    "12\t\u0006\u0002\u00022\f\u0003\u0002\u0002\u000234\t\u0007\u0002\u0002",
    "4\u000e\u0003\u0002\u0002\u000256\u0007?\u0002\u00026\u0010\u0003\u0002",
    "\u0002\u00027;\u0005\u0003\u0002\u00028;\u0005\u0005\u0003\u00029;\u0007",
    "a\u0002\u0002:7\u0003\u0002\u0002\u0002:8\u0003\u0002\u0002\u0002:9",
    "\u0003\u0002\u0002\u0002;<\u0003\u0002\u0002\u0002<:\u0003\u0002\u0002",
    "\u0002<=\u0003\u0002\u0002\u0002=\u0012\u0003\u0002\u0002\u0002>E\t",
    "\b\u0002\u0002?A\u0007\u000f\u0002\u0002@?\u0003\u0002\u0002\u0002@",
    "A\u0003\u0002\u0002\u0002AB\u0003\u0002\u0002\u0002BE\u0007\f\u0002",
    "\u0002CE\u0007\u000f\u0002\u0002D>\u0003\u0002\u0002\u0002D@\u0003\u0002",
    "\u0002\u0002DC\u0003\u0002\u0002\u0002EF\u0003\u0002\u0002\u0002FG\b",
    "\n\u0002\u0002G\u0014\u0003\u0002\u0002\u0002HJ\u0007/\u0002\u0002I",
    "H\u0003\u0002\u0002\u0002IJ\u0003\u0002\u0002\u0002JL\u0003\u0002\u0002",
    "\u0002KM\u0005\u0007\u0004\u0002LK\u0003\u0002\u0002\u0002MN\u0003\u0002",
    "\u0002\u0002NL\u0003\u0002\u0002\u0002NO\u0003\u0002\u0002\u0002OV\u0003",
    "\u0002\u0002\u0002PR\t\t\u0002\u0002QS\u0005\u0007\u0004\u0002RQ\u0003",
    "\u0002\u0002\u0002ST\u0003\u0002\u0002\u0002TR\u0003\u0002\u0002\u0002",
    "TU\u0003\u0002\u0002\u0002UW\u0003\u0002\u0002\u0002VP\u0003\u0002\u0002",
    "\u0002VW\u0003\u0002\u0002\u0002W\u0016\u0003\u0002\u0002\u0002XY\u0007",
    "]\u0002\u0002Y\u0018\u0003\u0002\u0002\u0002Z[\u0007_\u0002\u0002[\u001a",
    "\u0003\u0002\u0002\u0002\\]\u0007=\u0002\u0002]\u001c\u0003\u0002\u0002",
    "\u0002^_\u0007<\u0002\u0002_\u001e\u0003\u0002\u0002\u0002`a\u0007.",
    "\u0002\u0002a \u0003\u0002\u0002\u0002bc\u0007&\u0002\u0002c\"\u0003",
    "\u0002\u0002\u0002de\u00070\u0002\u0002e$\u0003\u0002\u0002\u0002fg",
    "\u0007,\u0002\u0002g&\u0003\u0002\u0002\u0002hl\u0007}\u0002\u0002i",
    "k\u000b\u0002\u0002\u0002ji\u0003\u0002\u0002\u0002kn\u0003\u0002\u0002",
    "\u0002lm\u0003\u0002\u0002\u0002lj\u0003\u0002\u0002\u0002mo\u0003\u0002",
    "\u0002\u0002nl\u0003\u0002\u0002\u0002op\u0007\u007f\u0002\u0002p(\u0003",
    "\u0002\u0002\u0002\f\u0002:<@DINTVl\u0003\b\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function SketchLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

SketchLexer.prototype = Object.create(antlr4.Lexer.prototype);
SketchLexer.prototype.constructor = SketchLexer;

Object.defineProperty(SketchLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

SketchLexer.EOF = antlr4.Token.EOF;
SketchLexer.DIRECTION = 1;
SketchLexer.PARTIAL_DIRECTION = 2;
SketchLexer.CURVATURE = 3;
SketchLexer.EQUAL = 4;
SketchLexer.WORD = 5;
SketchLexer.WHITESPACE = 6;
SketchLexer.NUMBER = 7;
SketchLexer.START_DATA = 8;
SketchLexer.END_DATA = 9;
SketchLexer.SEMICOLON = 10;
SketchLexer.COLON = 11;
SketchLexer.COMMA = 12;
SketchLexer.END_AREA = 13;
SketchLexer.END = 14;
SketchLexer.STAR = 15;
SketchLexer.LABEL = 16;

SketchLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

SketchLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

SketchLexer.prototype.literalNames = [ null, null, null, null, "'='", null, 
                                       null, null, "'['", "']'", "';'", 
                                       "':'", "','", "'$'", "'.'", "'*'" ];

SketchLexer.prototype.symbolicNames = [ null, "DIRECTION", "PARTIAL_DIRECTION", 
                                        "CURVATURE", "EQUAL", "WORD", "WHITESPACE", 
                                        "NUMBER", "START_DATA", "END_DATA", 
                                        "SEMICOLON", "COLON", "COMMA", "END_AREA", 
                                        "END", "STAR", "LABEL" ];

SketchLexer.prototype.ruleNames = [ "LOWERCASE", "UPPERCASE", "DIGIT", "DIRECTION", 
                                    "PARTIAL_DIRECTION", "CURVATURE", "EQUAL", 
                                    "WORD", "WHITESPACE", "NUMBER", "START_DATA", 
                                    "END_DATA", "SEMICOLON", "COLON", "COMMA", 
                                    "END_AREA", "END", "STAR", "LABEL" ];

SketchLexer.prototype.grammarFileName = "Sketch.g4";



exports.SketchLexer = SketchLexer;

